<template>
  <shipblu-card class="p-3">
    <p class="font-medium text-xl mb-3">{{$t('Flyers Size Contribution')}}</p>
    <flyers-size-contribution-pie-chart v-if="series.length > 0" :labels="labels" :series="series" class="flex justify-center items-center"></flyers-size-contribution-pie-chart>
    <div v-else class="rounded-full flex justify-center items-center mx-auto md:h-64 h-48 md:w-64 w-48 bg-empty-chart">
      <p class="text-grey-dark">{{$t('No Data Available')}}</p>
    </div>
  </shipblu-card>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import FlyersSizeContributionPieChart  from './FlyersSizeContributionPieChart'

export default {
  props: ['packageDistribution', 'packageDistributionLoader'],
  data () {
    return {
      series: [],
      labels: []
    }
  },
  watch: {
    packageDistribution () {
      this.series = this.packageDistribution.filter(res => res['package_size'] !== 'OSZ').map(res => res['packages_count'])       
      this.labels =  this.packageDistribution.filter(res => res['package_size'] !== 'OSZ').map(item => item['package_size'].split('-')[0])
    }
  },
  components: {
    FlyersSizeContributionPieChart,
    ShipbluCard
  }
}
</script>