<template>
  <shipblu-card class="flex items-center justify-center cursor-pointer" style="padding: 16px 10.5px;" :cardLoading="cardLoading">
    <statistics-data-pie-chart v-if="isShowChart" :statisticsData="statisticsData"></statistics-data-pie-chart>
    <div v-else class="rounded-full flex justify-center items-center md:h-64 h-48 md:w-64 w-48 bg-empty-chart">
      <p class="text-grey-dark">{{$t('No Data Available')}}</p>
    </div>
  </shipblu-card>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import StatisticsDataPieChart from './StatisticsDataPieChart.vue'

export default {
  props: ['statisticsData'],
  data () {
    return {
      cardLoading: true,
      isShowChart: false
    }
  },
  components: {
    ShipbluCard,
    StatisticsDataPieChart
  },
  watch:{
    statisticsData: {
      handler (val) {
        if (val.length === 4) {
          this.cardLoading = false
          this.isShowChart = this.statisticsData.every((ele) => {
            for (const key in ele) {
              if (key.includes('_count')) {
                return ele[key] !== 0 
              }
            }
          })
        }
      }
    },
    immediate: true
  }
}
</script>