<template>
  <shipblu-card :cardLoading="attemptsLoader" style="min-height: 100px;" class="p-3">
    <p class="font-medium text-xl mb-3">{{ $t('Delivery Attempts') }}</p>
    <delivery-attempts-line-chart class="w-full h-full" :attempts="attempts"></delivery-attempts-line-chart>
  </shipblu-card>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import DeliveryAttemptsLineChart from './DeliveryAttemptsLineChart.vue'

export default {
  props: ['attempts', 'attemptsLoader'],
  data () {
    return {
      series: [
        {
          name: 'Attempts',
          data: []
        }
      ]
    }
  },
  watch: {
    'attempts' (val) {
      if (val) {
        this.series[0].data = [this.attempts.orders_delivered_third_attempt_count, this.attempts.orders_delivered_second_attempt_count, this.attempts.orders_delivered_first_attempt_count]
      }
    }
  },
  components: {
    ShipbluCard,
    DeliveryAttemptsLineChart
  }
}
</script>