<template>
  <div id="main" :style="{ height: `${charHeight}px`, width: '100%' }"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'GeographicalAnalysisPieChart',
  props: ['series', 'governoratesLabels'],
  data () {
    return {
      myChart: null,
      charHeight:400,
      options: {
        color: ['#1C5BFE', '#497CFE', '#779DFE', '#B2C7FF', '#EBF0FF'],
        tooltip: {
          formatter: '{b} : {c} ({d}%)'
        },
        series: [
          {
            type: 'pie',
            radius: '100%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            label: {
              position: 'outside',
              fontSize: 12,
              fontWeight: 'bold',
              formatter: '{b}',
              distance : 100
            },
            itemStyle: {
              borderRadius: 5,
              borderColor: '#fff',
              borderWidth: 2
            },
            labelLine: {
              show: true,
              length: 15,
              length2: 15, 
              lineStyle: {
                color: '#aaa',
                width: 1
              }
            },
            data: []
          }
        ]
      }
    }
  },
  methods: {
    initChart () {
      const chartDom = document.getElementById('main')
      this.myChart = echarts.init(chartDom)
      const data = this.series.map((val, index) => {
        return {
          name : this.governoratesLabels[index],
          value : val
        }
      })
      this.options.series[0].data = data 
      this.myChart.setOption(this.options)
    },
    updateChartRadius () { 
      const width = window.innerWidth
      if (width <= 500) {
        this.options.series[0].radius = '40%'
        this.charHeight = 250
      } else if (width <= 990) {
        this.options.series[0].radius = '90%'
        this.charHeight = 400
      } else {
        this.options.series[0].radius = '70%'
        this.charHeight = 400
      }
      this.initChart()
      this.myChart.resize()
    }
  },
  mounted () {
    this.initChart()
    window.addEventListener('resize', this.updateChartRadius)
    this.updateChartRadius() 
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateChartRadius)
    if (this.myChart) {
      this.myChart.dispose()
    }
  },
  watch :{
    '$route.params.lang' () {
      this.initChart()
    }
  }
}
</script>
