<template>
  <div ref="chart" style="min-height: 250px" class="flex w-full justify-center items-center"></div>
</template>

<script>
import * as echarts from 'echarts'
import i18nData from '../../../i18n/i18nData'

export default {
  name: 'StatisticsDataSunburstChart',
  props: ['statisticsData'],
  data () {
    return {
      chart: null,
      data: [
        {
          name: null,
          itemStyle: {
            color: '#F1F1F1'
          },
          children: []
        }
      ]
    }
  },
  watch:{
    statisticsData () {
      this.updateChartData()
      this.initChart()
    },
    '$route.params.lang': {
      handler () {
        this.initChart()
      }
    }
  },
  mounted () {
    this.initChart()
    window.addEventListener('resize', this.resizeChart)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeChart)
  },
  methods: {
    initChart () {
      const chartDom = this.$refs.chart
      if (chartDom) {
        this.chart = echarts.init(chartDom)
        this.updateChartData()
      }
      const isSmallScreen = window.innerWidth <= 425
      const radius = isSmallScreen ? ['0', '60%'] : ['0', '90%']
      this.updateChartData(radius)
    },
    updateChartData (Radius = ['0', '95%']) {
      const colors = ['#B2C7FF', '#779DFE', '#497CFE', '#1C5BFE']
      const isSmallScreen = window.innerWidth <= 425
      if (this.statisticsData.length === 4) {        
        this.statisticsData.forEach((child, index) => {
          this.data[0].children[index] = {} 
          for (const key in child) {
            if (!key.includes('percentage') && !key.includes('pickup')) {
              if (!this.data[0].children[index]) {
                this.data[0].children[index] = {} 
              }
              this.data[0].children[index].value = child[key]
              if (key.split('_').length > 3) {
                this.data[0].children[index].name = i18nData[this.$i18n.locale][`total ${key.split('_')[2]}`] 
              } else if (key.split('_').length > 2) {
                this.data[0].children[index].name = i18nData[this.$i18n.locale][`total ${key.split('_')[1]}`] 
              }
              this.data[0].children[index].itemStyle = { color: colors[index] }
              this.data[0].name = i18nData[this.$i18n.locale]['total pickup']
            }
          }
        })
      }
      this.data[0].children.forEach(child => {
        child.label = {
          show: true,
          position: child.name === 'total pickup' ? 'inside' : 'outside',
          rotate: 0,
          fontSize: isSmallScreen ? 10 : 15,
          fontWeight: 'bold',
          padding: 15,
          fontFamily: this.$route.params.lang === 'ar' && 'Tajawal'
        }
      })
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c}'
        },
        series: [
          {
            type: 'sunburst',
            data: [...this.data],
            radius: Radius,
            minAngle: 10,
            itemStyle: {
              borderRadius: 10,
              borderWidth: 2,
              borderColor: '#fff'
            },
            label: {
              show: true,
              rotate: 0,
              fontWeight: 'bold',
              fontSize: isSmallScreen ? 10 : 15
            },
            labelLine: {
              show: true,
              length: 15,
              length2: 15, 
              lineStyle: {
                color: '#aaa',
                width: 1
              }
            },
            emphasis: {
              itemStyle: {
                borderWidth: 10
              }
            }
          }
        ]
      }
      this.chart.setOption(option)
    },
    resizeChart () {
      if (this.chart) {
        this.chart.resize()
      }
    }
  }
}
</script>