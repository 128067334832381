<template>
 <shipblu-card class="p-3">
  <p class="font-medium text-xl mb-3">{{$t('Geographical Analysis')}}</p>
  <div class="grid lg:grid-cols-5 grid-cols-1 gap-y-4">
    <div class="lg:col-span-2 col-span-1 flex items-center justify-center">
      <geographical-analysis-pie-chart v-if ="series.length > 1" class="flex justify-center items-center" :series="series" :governoratesLabels="governoratesLabels"></geographical-analysis-pie-chart>
      <div v-else class="rounded-full flex justify-center items-center md:h-64 h-48 md:w-64 w-48 bg-empty-chart">
        <p class="text-grey-dark">{{$t('No Data Available')}}</p>
      </div>
    </div>
    <geographical-table class="w-full lg:col-span-3 col-span-1" :tableHeight="'500px'" :tableHeaders="tableHeaders" :citiesData="citiesData"></geographical-table>
  </div>
 </shipblu-card>
</template>
<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import GeographicalTable from './GeographicalTable.vue'
import GeographicalAnalysisPieChart from './GeographicalAnalysisPieChart.vue'
import { sendRequest } from '../../../http/axios/requestHelper'
import i18nData from '@/i18n/i18nData.js'

export default {
  props: ['geoData', 'merchantID', 'startDate', 'endDate'],
  data () {
    return {
      geoLoader: false,
      tableHeaders: [ 
        { text: 'City', field: 'city' },
        { text: 'Picked Orders', field: 'orders_pickup_count' },
        { text: 'Delivered', field: 'orders_delivered_count' },
        { text: 'Delivery %', field: 'delivered_percentage' }
      ],
      citiesData: [],
      series: [],
      governoratesLabels :[]
    }
  },
  watch: {
    geoData (val) {
      this.updateChartData(val)
      this.loadCityData()
    },
    '$route.params.lang' () {
      this.updateChartData(this.geoData)
    }
  },
  methods: {
    loadCityData () {
      this.geoLoader = true
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/cities-distribution?merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`, 'get', null, true,
        (response) => {
          this.citiesData = response.data
          this.geoLoader = false
        }
      )
    },
    updateChartData (val) {
      this.governoratesLabels = []
      this.series = val.map(item => item.orders_pickup_count)
      val.map(item => {
        this.governoratesLabels.push(i18nData[this.$i18n.locale][item.governorate] ? i18nData[this.$i18n.locale][item.governorate] : item.governorate)
      })
    }
  },
  components: {
    ShipbluCard,
    GeographicalTable,
    GeographicalAnalysisPieChart
  }
}
</script>