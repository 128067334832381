<template>
  <shipblu-card :cardLoading="lastAttemptReasonLoader" class="p-3">
    <p class="mb-3">
      <span class="font-medium text-xl">{{ $t('Unsuccessful Deliveries Reasons') }}</span>
      <vx-tooltip :text="$t('The count of the last attempt reasons for unsuccessful orders.')" class="inline-block">
        <span class="material-icons-round text-black text-base mx-1 cursor-default">info</span>
      </vx-tooltip>
    </p>
    <geographical-table :tableHeight="'400px'" :showIndex="true" :tableHeaders="labelsDic" :citiesData="lastAttemptReason"></geographical-table>
  </shipblu-card>
</template>
  
<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import GeographicalTable from './GeographicalTable.vue'

export default {
  props: ['lastAttemptReason', 'lastAttemptReasonLoader'],
  data () {
    return {
      labels: null,
      data: null,
      labelsDic: [
        { text: 'Reason', field: 'attempt_reason' },
        { text: 'Count', field: 'attempt_reason_count' },
        { text: 'Percentage', field: 'attempt_reason_percentage' }
      ]
    }
  },
  components: {
    ShipbluCard,
    GeographicalTable
  }
}
</script>