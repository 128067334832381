<template>
  <div id="chart" ref="chart" :style="{ height: `${charHeight}px`, width: '100%' }"></div>
</template>
  
<script>
import * as echarts from 'echarts'

export default {
  name: 'FlyersSizeContributionPieChart',
  props: ['labels', 'series'], 
  data () {
    return {
      chart: null,
      charHeight:400,
      chartOptions: {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        }, 
        color: ['#1C5BFE', '#497CFE', '#779DFE', '#B2C7FF', '#EBF0FF'],
        series: [
          {
            type: 'pie',
            radius: '100%',
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 5,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
              formatter: '{b}',   
              position: 'outside',
              fontSize: 12,
              fontWeight: 'bold'
            },
            labelLine: {
              show: true,
              length: 15,
              length2: 15, 
              lineStyle: {
                color: '#aaa',
                width: 1
              }
            },
            data: []
          }
        ]
      }
    }
  },
  mounted () {
    this.initChart()
    window.addEventListener('resize', this.handleWindowSizeChange)
  },
  watch: {
    data: 'updateChart',
    labels: 'updateChart'
  },
  methods: {
    handleWindowSizeChange () {
      this.windowWidth = window.innerWidth
      this.updateChart() 
    },
    initChart () {
      const chartDom = this.$refs.chart
      this.chart = echarts.init(chartDom)
      this.updateChart()
      window.addEventListener('resize', this.chart.resize)
    },
    updateChart () {
      const width = window.innerWidth
      if (width <= 500) {
        this.chartOptions.series[0].radius = '40%'
        this.charHeight = 200
      } else if (width <= 990) {
        this.chartOptions.series[0].radius = '90%'
        this.charHeight = 400
      } else {
        this.chartOptions.series[0].radius = '70%'
        this.charHeight = 400
      }
      const seriesData = this.series.map((value, index) => ({
        name: this.labels[index],
        value
      }))    
      this.chart.setOption({
        ...this.chartOptions,
        series: [
          {
            ...this.chartOptions.series[0],
            data: seriesData
          }
        ]
      })
    }
  },
  beforeDestroy () {
    if (this.chart) {
      window.removeEventListener('resize', this.chart.resize)
      this.chart.dispose()
    }
    window.addEventListener('resize', this.handleWindowSizeChange)
  }
}
</script>