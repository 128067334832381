<template>
  <div>
    <h2 class="mb-6">{{$t('Analytics')}}</h2>
    <shipblu-card class="px-5 py-4 mb-5">
      <delivery-performance-date @callData="callData" @startDate="startDate = $event" @endDate="endDate = $event" />
    </shipblu-card>
    <shipblu-card class="p-5 grid grid-cols-8 gap-5 sm:mb-0 mb-[86px]">
      <statistics :statisticsData="statisticsData" :loaders="statisticsLoaders" class="lg:col-span-4 col-span-8" />
      <delivery-success-rate :deliveryRate="deliveryRate" :successRateLoader="successRateLoader" class="lg:col-span-4 col-span-8" />
      <average-delivery-time :averageDeliveryTime="averageDeliveryTime" :averageTimeLoader="averageTimeLoader" class="lg:col-span-3 col-span-8" />
      <delivery-attempts :attempts="attempts" :attemptsLoader="attemptsLoader" class="lg:col-span-5 col-span-8" />
      <geographical-analysis :geoData="geoData" :merchantID="merchantID" :startDate="startDate" :endDate="endDate" class="col-span-8" />
      <last-attempt-reason-distribution-table class="lg:col-span-5 col-span-8 lg:order-1 order-2" :lastAttemptReason="lastAttemptReason" :lastAttemptReasonLoader="lastAttemptReasonLoader" />
      <package-distribution class="lg:col-span-3 col-span-8 lg:order-1 order-1" :packageDistribution="packageDistribution" :packageDistributionLoader="packageDistributionLoader" />
      <shipblu-card class="px-5 py-4 mb-5 col-span-8 lg:order-1 order-3">
        <financial-daily-distribution v-if="financialDailyDistribution.length > 0" :financialDailyDistribution="financialDailyDistribution" :startDate="startDate" :endDate="endDate"/>
        <div v-else class="flex justify-center items-center mx-auto md:h-64 h-48">
          <p class="text-grey-dark sm:text-[16px] text-sm">{{$t('No Data Available')}}</p>
        </div>
        <div class="flex sm:flex-row flex-col justify-center items-center sm:gap-10 gap-6 mt-12">
          <shipblu-card v-for="(data, index) in financialData" :key="index" class="sm:min-w-[160px] min-w-full cursor-pointer p-3 h-20 flex flex-col gap-2 items-center justify-center">
            <span class="capitalize font-bold">{{ $t(data.label)}}</span>
            <span>{{ Number(data[Object.keys(data)[1]]).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
          </shipblu-card>
        </div>
      </shipblu-card>
    </shipblu-card>
  </div>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import DeliveryPerformanceDate from './analytics/DeliveryPerformanceDate.vue'
import Statistics from './analytics/Statistics.vue'
import DeliverySuccessRate from './analytics/DeliverySuccessRate.vue'
import DeliveryAttempts from './analytics/DeliveryAttempts.vue'
import AverageDeliveryTime from './analytics/AverageDeliveryTime.vue'
import { sendRequest } from '../../http/axios/requestHelper'
import PackageDistribution from './analytics/PackageDistribution.vue'
import LastAttemptReasonDistributionTable from './analytics/LastAttemptReasonDistributionTable.vue'
import GeographicalAnalysis from './analytics/GeographicalAnalysis.vue'
import financialDailyDistribution from './analytics/DailyRevenueLineChart.vue'

export default {
  data () {
    return {
      merchantID: '',
      startDate: '',
      endDate: '',
      statisticsLoaders: {
        totalPickedLoader: false,
        totalDeliveredLoader: false,
        totalReturnedLoader: false,
        inProgressLoader: false
      },
      attemptsLoader: false,
      averageTimeLoader: false,
      successRateLoader: false,
      packageDistributionLoader: false,
      lastAttemptReasonLoader: false,
      packageDistribution: [],
      lastAttemptReason: [],
      statisticsData: [],
      attempts: [],
      deliveryRate: {},
      averageDeliveryTime: 0,
      geoData: [],
      financialData: [],
      financialDailyDistribution: []
    }
  },
  methods: {
    loadStatistics (loader, endpoint, params) {
      this.statisticsLoaders[loader] = true
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/${endpoint}?${params}`, 'get', null, true, 
        (response) => {
          this.statisticsData = [...this.statisticsData, response.data]
          this.statisticsLoaders[loader] = false
        }
      )
    },
    loadSuccessRate () {
      this.successRateLoader = true
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/delivery-success-rate?merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`, 'get', null, true, 
        (response) => {
          this.deliveryRate = response.data
          this.successRateLoader = false
        }
      )
    },
    loadDeliveryAttempts () {
      this.attemptsLoader = true
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/attempts-number?merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`, 'get', null, true, 
        (response) => {
          this.attempts = response.data
          this.attemptsLoader = false
        }
      )
    },
    loadAverageDeliveryTime () {
      this.averageTimeLoader = true
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/average-delivery-time?merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`, 'get', null, true, 
        (response) => {
          this.averageDeliveryTime = response.data.delivered_orders_cycle_avg
          this.averageTimeLoader = false
        }
      )
    },
    loadGeoData () {
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/governorates-distribution?merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`, 'get', null, true,
        (response) => {
          const data = response.data
          let otherOrdersPickupCount = 0
          data.slice(Math.max(4, 0)).map(item => {
            otherOrdersPickupCount += item.orders_pickup_count
          })
          this.geoData = data.slice(0, 4)
          this.geoData.push({
            governorate: 'Others',
            orders_pickup_count: otherOrdersPickupCount
          })
        }
      )
    },
    DataPackageDistributionPieChart () {
      this.packageDistributionLoader = true
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/packages-size?merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`, 'get', null, true, 
        (response) => {
          this.packageDistribution = response.data
          this.packageDistributionLoader = false
        }
      )
    },
    DataLastAttemptReason () {
      this.lastAttemptReasonLoader = true
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/attempt-reasons-distribution?merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`, 'get', null, true, 
        (response) => {
          this.lastAttemptReason = response.data
          this.lastAttemptReasonLoader = false
        }
      )
    },
    loadFinancial (label, endpoint, params) {
      sendRequest(true, false, this, `api/v1/dash/analytical/financial-performance/${endpoint}?${params}`, 'get', null, true, 
        (response) => {
          this.financialData = [...this.financialData, {label, ...response.data}]
        }
      )
    },
    loadFinancialDailyDistribution () {
      sendRequest(true, false, this, `api/v1/dash/analytical/financial-performance/financial-daily-distribution?merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`, 'get', null, true, 
        (response) => {
          this.financialDailyDistribution = response.data
        }
      )
    },
    callData () {
      this.statisticsData = []
      this.financialData = []
      this.loadStatistics('totalPickedLoader', 'orders-pickup', `merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`)
      this.loadStatistics('totalDeliveredLoader', 'orders-returned', `merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`)
      this.loadStatistics('totalReturnedLoader', 'orders-delivered', `merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`)
      this.loadStatistics('inProgressLoader', 'orders-inprogress', `merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`)
      this.loadSuccessRate()
      this.loadDeliveryAttempts()
      this.loadAverageDeliveryTime()
      this.loadGeoData()
      this.DataPackageDistributionPieChart()
      this.DataLastAttemptReason()
      this.loadFinancial('Cash Collected', 'cash-collected', `merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`)
      this.loadFinancial('Refund Issued', 'refund-issued', `merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`)
      this.loadFinancial('Service Fees', 'service-fees', `merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`)
      this.loadFinancialDailyDistribution()
    }
  },
  components: {
    ShipbluCard,
    DeliveryPerformanceDate,
    Statistics,
    DeliverySuccessRate,
    DeliveryAttempts,
    AverageDeliveryTime,
    GeographicalAnalysis,
    PackageDistribution,
    LastAttemptReasonDistributionTable,
    financialDailyDistribution
  },
  created () {
    this.merchantID = JSON.parse(localStorage.getItem('merchant') !== null) ? JSON.parse(localStorage.getItem('merchant')).id : ''
  }
}
</script>
<style>
.bg-empty-chart{
  background-color: #EBF0FF
}
</style>