<template>
  <shipblu-card :cardLoading="successRateLoader" class="p-2">
    <p class="font-medium text-xl p-1">{{ $t('Delivery Success Rate') }}</p>
    <div class="flex justify-center">
      <delivery-success-rate-progress-chart :value="`${deliveryRate.delivery_success_rate ? deliveryRate.delivery_success_rate.toLocaleString('en', {minimumFractionDigits: 1, maximumFractionDigits: 1}) : 0}`" ></delivery-success-rate-progress-chart>
    </div>
    <div class="flex items-center gapx-2 justify-between card-footer rounded-t-none rounded-lg py-2 px-5 mt-4">
      <div class="flex items-center gap-2">
        <img src="@/assets/images/pages/prize.svg" width="25" alt="" />
        <p class="font-semibold text-black leading-none">{{$t('1st Attempt Delivery Rate:')}}</p>
      </div>
      <p class="font-semibold text-blue-100 text-lg leading-none ml-auto">{{ deliveryRate.first_attempt_delivery_rate ? deliveryRate.first_attempt_delivery_rate.toLocaleString('en', {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%' : '0%'}}</p>
    </div>
  </shipblu-card>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import DeliverySuccessRateProgressChart from './DeliverySuccessRateProgressChart'

export default {
  props: ['deliveryRate', 'successRateLoader'],
  components: {
    ShipbluCard,
    DeliverySuccessRateProgressChart
  }
}
</script>
