<template>
  <div ref="chart" style="min-height: 200px; width: 100%;"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'DeliveryAttemptsLineChart',
  props: ['attempts'],
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart()
      window.addEventListener('resize', this.resizeChart)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeChart)
    if (this.chart) {
      this.chart.dispose()
    }
  },
  watch: {
    attempts (val) {
      this.updateChartData(val)
    }
  },
  methods: {
    initChart () {
      const chartDom = this.$refs.chart
      if (chartDom) {
        this.chart = echarts.init(chartDom)
        this.updateChartData(this.attempts)
      }
    },
    updateChartData (newData) {
      const option = {
        animation: true,
        animationDuration: 1000,
        animationDurationUpdate: 1000,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear',
        color: '#1C5BFE',
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        grid: {
          top: '20%',
          left: '0%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {
          type: 'category',
          data: ['1st.', '2nd.', '3rd.'],
          axisTick: { alignWithLabel: true },
          axisLabel: {
            fontWeight: 'bold',
            fontSize: 12
          }
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            fontWeight: 'bold',
            fontSize: 12
          }
        },
        series: [
          {
            name: 'Delivery Attempts',
            type: 'bar',
            barWidth: '70%',
            data: [
              newData.orders_delivered_first_attempt_count,
              newData.orders_delivered_second_attempt_count,
              newData.orders_delivered_third_attempt_count
            ],
            itemStyle: {
              borderRadius: [0, 8, 8, 0]
            }
          }
        ]
      }
      this.chart.setOption(option)
    },
    resizeChart () {
      if (this.chart) {
        this.chart.resize()
      }
    }
  }
}
</script>