<template>
  <div ref="chartRef" style="width: 60%; height: 220px;"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'DeliverySuccessRateProgressChart',
  props: ['value'],
  data () {
    return {
      chart: null,
      option: {
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: { show: false },
            max: 100,
            progress: {
              show: true,
              overlap: false,
              roundCap: false,
              clip: false,
              itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [{ offset: 1, color: '#1C5BFE' }]
                }
              }
            },
            axisLine: { lineStyle: { width: 20 }},
            splitLine: { show: false, distance: 0, length: 20 },
            axisTick: { show: false },
            axisLabel: { show: false, distance: 20 },
            data: [
              {
                value: this.value,
                title: { offsetCenter: ['0%', '0%'] },
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%'],
                  fontSize: 22,
                  borderWidth: 0
                }
              }
            ],
            detail: {
              width: 20,
              height: 12,
              fontSize: 8,
              borderColor: 'inherit',
              borderWidth: 1,
              formatter: '{value}%',
              color: '#1C5BFE'
            }
          }
        ],
        animationDuration: 2000
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$refs.chartRef) {
        this.initChart()
        window.addEventListener('resize', this.resizeChart)
      }
    })
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
    window.removeEventListener('resize', this.resizeChart)
  },
  methods: {
    initChart () {
      if (this.$refs.chartRef) {
        this.chart = echarts.init(this.$refs.chartRef)
        this.chart.setOption(this.option)
      }
    },
    resizeChart () {
      if (this.chart) this.chart.resize()
    }
  }
}
</script>