<template>
  <div ref="financialDailyDistribution" style="max-width: 100%; height: 400px; overflow-x: scroll;"></div>
</template>

<script>
import * as echarts from 'echarts'
import i18nData from '../../../i18n/i18nData'

export default {
  name: 'financialDailyDistribution',
  props: ['financialDailyDistribution'],
  data () {
    return {
      chartInstance: null
    }
  },
  mounted () {
    this.initChart()
  },
  computed: {
    formattedData () {
      if (!this.financialDailyDistribution || this.financialDailyDistribution.length === 0) {
        return { dates: [], series: [] }
      }
      const dates = this.financialDailyDistribution.map(item => item.date) || []
      const series = [
        {
          name: i18nData[this.$i18n.locale]['Cash Collected'],
          type: 'line',
          emphasis: { focus: 'series' },
          data: this.financialDailyDistribution.map(item => item.cash_collected_sum || 0)
        },
        {
          name: i18nData[this.$i18n.locale]['Refund Issued'],
          type: 'line',
          emphasis: { focus: 'series' },
          data: this.financialDailyDistribution.map(item => item.refund_issued_sum || 0)
        },
        {
          name: i18nData[this.$i18n.locale]['Service Fees'],
          type: 'line',
          emphasis: { focus: 'series' },
          data: this.financialDailyDistribution.map(item => item.service_fees_sum || 0)
        }
      ]
      return { dates, series }
    }
  },
  methods: {
    initChart () {
      const chartDom = this.$refs.financialDailyDistribution
      if (!chartDom) return
      this.chartInstance = echarts.init(chartDom)
      this.updateChart()
      window.addEventListener('resize', () => {
        if (this.chartInstance) this.chartInstance.resize()
      })
    },
    updateChart () {
      if (!this.chartInstance) return
      const { dates, series } = this.formattedData
      if (!dates.length) {
        this.chartInstance.clear() 
        return
      }
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'cross' },
          textStyle: {
            fontFamily: this.$route.params.lang === 'ar' && 'Tajawal' 
          }
        },
        legend: {
          data: series.map(s => s.name),
          textStyle: {
            fontFamily: this.$route.params.lang === 'ar' && 'Tajawal'
          }
        },
        grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
        xAxis: [
          { type: 'category',
            boundaryGap: false,
            data: dates,
            axisLabel: {
              fontFamily: this.$route.params.lang === 'ar' && 'Tajawal'
            }
          }
        ],
        yAxis: [
          { type: 'value',
            axisLabel: {
              fontFamily: this.$route.params.lang === 'ar' && 'Tajawal'
            }
          }
        ],
        series
      }
      this.chartInstance.setOption(option)
    }
  },
  watch: {
    '$route.params.lang': {
      handler () {
        this.initChart()
      }
    },
    'financialDailyDistribution': {
      handler () {
        this.updateChart()
      },
      deep: true
    }
  }
}
</script>
